<template>
  <div class="building-list">
      <!--遍历所有楼栋-->
      <div v-for="(building,i) in buildingList" :key="i" class="building">
        <div class="unit-list">
            <!--遍历所有单元-->
            <div v-for="(unit,j) in building.unitList" :key="i+'-'+j" class="unit">
                <div class="unit-label">
                    <input type="checkbox"  v-if="editMode"
                    :value="i+','+ j" v-model="checkedItems"/>
                    {{ building.name}}<br/>
                    {{unit.name}}
                </div>
                <div class="floor-list">
                    <!--遍历所欲楼层-->
                    <div v-for="(floor,k) in unit.floorList" :key="i+','+j+','+k" class="floor">
                        <!--所有房屋-->
                        <div v-for="room in floor.roomList" :key="room.id" class="room" 
                        :class="{active: room.personCount!=null && room.personCount>0}">
                            <input type="checkbox"  v-if="editMode"
                            :value="i+','+ j +','+ k +',' + room.id" v-model="checkedItems"/>
                            <span @click="roomClick(room)">{{room.number}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
    name: "RoomDiagram",
    props: {
        "editMode" : {
            type: Boolean,
            default: false
        },
        "buildingList": {
            type: Array
        },
        "filterBuildingId": {
            type: String,
            default: ""
        },
        "filterUnitId": {
            type: String,
            default: ""
        },
        "filterRoomName": {
            type: String,
            default: ""
        },
        "value": {
            type: Array,
            default: ()=> {
                return [];
            }
        }
    },
    data() {
        return {
            checkedItems: []
        }
    },
    mounted() {
 
    },
    watch: {
        checkedItems(newVal) {
            console.log("checkedItems changed!");
            console.log(newVal);

            this.$emit("input",newVal);
        },
        value(newVal) {
            console.log("value changed!");
            this.checkedItems = newVal;  //如果newVal与当前值相同，不会再触发checkItems的侦听
        }
    },
    methods: {
        filterBuilding(list) {
            if(this.filterBuildingId.length>0){
                return list.filter(item=>item.id==this.filterBuildingId);
            }
            else{
                return list;
            }
        },
        filterUnit(list) {
            if(this.filterUnitId.length>0){
                return list.filter(item=>item.id==this.filterUnitId);
            }
            else{
                return list;
            }
        },
        filterRoom(list) {
            if(this.filterRoomName.length>0){
                return list.filter(item=>item.number.indexOf(this.filterRoomName)!=-1);
            }
            else{
                return list;
            }
        },
        simulateData() {
            var roomIndex = 1;

            for(var i=1;i<=2;i++) {
                var building = {
                    name : i + "栋",
                    unitList: []
                }

                for(var j=1;j<=3;j++) {
                    var unit = {
                        name: `${j}单元`,
                        floorList: []
                    }
                    
                    for(var k=1;k<=10;k++){
                        //每层4户
                        var roomList = [];

                        for(var l=1;l<=4;l++) {
                            roomList.push({
                                id: roomIndex++,
                                name: `${k}0${l}`,
                                checked: false
                            });
                        }

                        unit.floorList.push({roomList});
                    }

                    building.unitList.push(unit);
                }

                this.buildingList.push(building);
            }
        },
        deleteSelectedRoom() {
            //buildingList为计算属性，还是服务器端删除后刷新
            if(this.checkedItems.length==0){
                this.$message({
                    message: '请先选择要删除的房屋!',
                    type: 'warning'
                });
            }
            else{
                var removeUnitList = [];

                this.checkedItems.forEach(value=>{
                    console.log(value);

                    var arr = value.split(',');

                    var i = parseInt(arr[0]);
                    var j = parseInt(arr[1]);

                    if(arr.length==2){
                        //选择单元
                        removeUnitList.push({
                            building: this.buildingList[i],
                            unit: this.buildingList[i].unitList[j]
                        });
                    }
                    else{
                        //选择房间
                        var k = parseInt(arr[2]);
                        var roomId = arr[3];

                        var roomList = this.buildingList[i].unitList[j].floorList[k].roomList;

                        var room = roomList.filter(room=>room.id == roomId)[0];

                        roomList.splice(roomList.indexOf(room),1);
                    }
                })

                removeUnitList.forEach(item=>{
                    var unitList = item.building.unitList;
                    unitList.splice(unitList.indexOf(item.unit),1);
                });

                this.checkedItems = [];
            }
        },
        roomClick(room) {
            this.$emit("roomClick", room);
        }
    }
}
</script>
<style scoped>
.building-list{
    display: flex;
    flex-direction: row;
    flex-wrap : wrap;
    margin-top:10px;
}

.unit {
    display: flex;
    flex-direction: row;
    flex-wrap : wrap;
}

.unit-label{
    width: 100px;
    padding-top: 10px;
    padding-left: 10px;
}

.unit-list {
    display: flex;
    flex-direction: row;
    flex-wrap : wrap;
}

.floor-list {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-top: 5px;
}

.floor {
    display: flex;
    flex-direction: row;
}

.room {
    background:url('~@/assets/room.png');
    background-size:contain;
    background-repeat: no-repeat;
    text-align: center;
    width: 64px;
    height:32px;
    line-height: 32px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: rgb(206,214,223);
}

.active {
    background: rgb(74,148,254);
    border-radius: 5px;
    color:white;
}
</style>