<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible="visible"
    :title="title"
    :modal-append-to-body="false"
    append-to-body
    style="text-align:left;"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="单元名称" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入单元名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="单元编号" prop="number">
          <el-input v-model="formModel.number" placeholder="请输入单元编号" style="width:300px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSubmit" :loading="submitting">{{unitId==null || unitId.length==0 ? "添加" : "更新"}}</el-button>
      <el-button @click="closeDialog">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import communityUnitApi from "@/api/base/communityUnit";

export default {
  props: {
    "visible": {
      type: Boolean,
      default: false
    },
    "buildingId": {
      type: String,
      default: ""
    },
    "unitId": {
      type: String,
      default: ""
    },
    "title": {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      ruleValidate: {
        name: [
          { required: true, message: '单元名称不能为空', trigger: 'blur' }
        ],
        number: [
          { required: true, message: '单元编号不能为空', trigger: 'blur' }
        ]
      },
      loading: false,
      submitting: false,
      formModel: {}
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            self.formModel.buildingId = self.buildingId;
            self.submitting = true;

            if (id == null || id.length == 0) {
              return communityUnitApi.add(self.formModel);
            }
            else {
              return communityUnitApi.update(self.formModel);
            }
          })().then((response)=>{
            self.submitting = false;
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", false);
            }
          });
        }
      });
    }
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.unitId==null || self.unitId.length == 0) {
        return communityUnitApi.create()
      }
      else {
        return communityUnitApi.edit(self.unitId)
      }
    })().then(response => {
      var jsonData = response.data;
      self.loading = false;

      if (jsonData.result) {
        self.formModel = jsonData.data;
      } else {
        self.$message.error(jsonData.message + "");
      }
    }).catch(error => {
      self.$message.error(error + "");
    });
  }
};
</script>