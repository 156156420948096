import request from '@/utils/request'
import constant from '@/constant'


function hikCloudCommunityList(formData) {
    return request.post(constant.serverUrl + "/base/communityInfo/hikCloudCommunityList", formData);
}

function importCommunityFromHikCloud(community) {
    return request.post(constant.serverUrl + "/base/communityInfo/importCommunityFromHikCloud",community,{
        headers: {
          "Content-Type": "application/json"
        }
    });
}

export default{
    hikCloudCommunityList,importCommunityFromHikCloud
}