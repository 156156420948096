<template>
  <el-dialog
        :visible="visible"
        :title="title"
        :modal-append-to-body="true"
        append-to-body
        :close-on-click-modal="false"
        style="text-align: left"
        width="400"
        @close="closeDialog"
    >
        <div class="user-panel" v-loading="loading">
            <el-form ref="form" :model="formModel" 
            :rules="ruleValidate" :label-width="'120px'">
                <el-form-item label="所在楼栋" prop="buildingId">
                     <el-select size="mini" v-model="formModel.buildingId" style="width:150px;">
                        <el-option
                            v-for="item in buildingList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所在单元" prop="unitId">
                    <el-select size="mini" v-model="formModel.unitId" style="width:150px;">
                        <el-option
                            v-for="item in unitList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所在层数" prop="floor">
                    <el-select size="mini" v-model="formModel.floor" style="width:150px;">
                        <el-option
                            v-for="floor in floorList"
                            :key="floor"
                            :label="floor"
                            :value="floor">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="门牌号" prop="number">
                    <el-input
                        v-model="formModel.number"
                        placeholder="请输入门牌号"
                        style="width:200px"
                        size="mini"
                    ></el-input>
                </el-form-item>
                <el-form-item label="户室名称" prop="name">
                    <el-input
                        v-model="formModel.name"
                        placeholder="请输入户室名称"
                        style="width:200px"
                        size="mini"
                    ></el-input>
                </el-form-item>
                <el-form-item label="入住人员">
                    <div class="member-list">
                        <div class="member-item" v-for="(member,index) in formModel.memberList" :key="index">
                            <div class="member-tag" :class="{
                                owner: member.personRoleId=='6', 
                                family: member.personRoleId=='7', 
                                tenant: member.personRoleId=='8'}">
                            {{displayMemberTag(member)}}
                            </div>
                            <span v-html="member.personName"></span>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleSubmit" :loading="loading">{{roomId==null || roomId.length==0 ? "添加" : "更新"}}</el-button>
            <el-button @click="closeDialog">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import communityBuildingApi from '@/api/base/communityBuilding';
import communityRoomApi from '@/api/base/communityRoom';

export default {
    props: {
        "visible": {
            type: Boolean,
            default: false
        },
        "title": {
            type: String,
            default: "楼栋信息"
        },
        "roomId": {
            type: String,
            default: ""
        },
        "communityId": {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            ruleValidate: {
                buildingId: [
                    { required: true, message: '楼栋不能为空', trigger: 'blur' }
                ],
                unitId: [
                    { required: true, message: '单元不能为空', trigger: 'blur' }
                ],
                floor: [
                    { required: true, message: '楼层不能为空', trigger: 'blur' }
                ],
                number: [
                    { required: true, message: '门派号不能为空', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '名称不能为空', trigger: 'blur' }
                ]   
            },
            showDialog: true,
            loading: false,
            submitting: false,
            formModel: {
                communityId: "",
                buildingId: "",
                unitId: "",
                memberList: []
            },
            buildingList: [],
            unitList: [],
            floorList: []
        };
    },
    watch: {
        "formModel.buildingId": function(newVal,oldVal) {
            if(newVal!=null && newVal.length>0){
                var building = this.buildingList.filter(item=>item.id==newVal)[0];

                if(building!=null){
                    this.unitList = building.unitList;
                    this.floorList = [];

                    for(var i=1;i<=building.floorUpCount;i++){
                        this.floorList.push(i);
                    }
                }
            }
            else{
                this.unitList = [];
            }
        }
    },
    mounted() {
        //根据社区读取楼栋+单元+楼层
        var formData = new FormData();
        formData.append("communityId", this.communityId);

        communityBuildingApi.queryBuildingList(formData)
        .then(resp=>{
            var jsonData = resp.data;

            if(jsonData.result){
                this.buildingList = jsonData.data;
                this.buildingId = "";
            }

            //读取房间信息
            this.loadRoom();
        });
    },
    methods: {
        loadRoom() {
            var self = this;

            (function () {
                self.loading = true;
                
                if (self.roomId==null || self.roomId.length == 0) {
                    return communityRoomApi.create();
                } else {
                    return communityRoomApi.edit(self.roomId);
                }
            })().then((response) => {
                var jsonData = response.data;
                self.loading = false;

                if (jsonData.result) {
                    self.formModel = jsonData.data;

                    console.log(self.formModel);
                } else {
                    self.$message.error(jsonData.message + "");
                }
            })
            .catch((error) => {
                self.$message.error(error + "");
            });
        },
        handleSubmit() {
            var self = this;
            self.formModel.communityId = self.communityId;

            this.$refs["form"].validate((valid) => {
                if (valid) {
                    (function () {
                        var id = self.formModel.id;
                        self.loading = true;

                        if (id == null || id.length == 0) {
                            return communityRoomApi.add(self.formModel);
                        } else {
                            return communityRoomApi.update(self.formModel);
                        }
                    })().then(function (response) {
                        self.loading = false;

                        var jsonData = response.data;

                        if (jsonData.result) {
                            self.$message({
                                message: "保存成功!",
                                type: "success",
                            });

                            self.$emit("close", true);
                        } else {
                            self.$message({
                                message: jsonData.message + "",
                                type: "warning",
                            });
                        }
                    });
                }
            });
        },
        closeDialog() {
            this.$emit("update:visible",false);
        },
        displayMemberTag(member) {
            var text = "";

            switch(member.personRoleId){
                case "6":
                    text = "业主";
                    break;
                case "7":
                    text = "家属";
                    break;
                case "8":
                    text = "租户";
                    break;    
            }

            return text;
        }
    }
}
</script>
<style scoped>
.member-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.member-item{
    margin:0px 5px 5px 0px;
}

.member-tag {
    display:inline-block;
    border-radius: 4px;
    min-width: 30px;
    padding: 0px 4px;
    height: 24px;
    margin-right: 5px;
    margin-left: 5px;
    line-height: 24px;
}

.owner {
    background-color: #409eff;
    color: white;
}

.family {
    background-color: #67c23a;
    color: white;
}

.tenant{
    background-color: #e6a23c;
    color: white;
}
</style>