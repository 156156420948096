import request from '@/utils/request'
import constant from '@/constant'

function list(formData){
  return request.post(constant.serverUrl + "/base/communityUnit/list", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/communityUnit/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/communityUnit/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/communityUnit/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/communityUnit/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/communityUnit/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/communityUnit/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  list,create,edit,add,update,remove,batchRemove
}