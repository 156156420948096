<template>
  <div>
    <el-dialog
        :visible="visible"
        title="楼栋单元管理"
        :modal-append-to-body="true"
        append-to-body
        :close-on-click-modal="false"
        style="text-align: left"
        width="80%"
        @close="closeDialog"
    >
      <div>
        <el-button icon="el-icon-plus" size="mini" @click="handleAdd">新增单元</el-button>
        <el-button icon="el-icon-delete" size="mini" @click="handleBatchDelete">批量删除</el-button>
      </div>
      <el-table
          :data="unitList"
          v-loading="loading"
          stripe
          @selection-change="handleSelectionChange"
      >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="name" label="单元名称" width="200"></el-table-column>
          <el-table-column prop="number" label="单元编号" width="100"></el-table-column>
          <el-table-column label="操作" fixed="right">
              <template slot-scope="{ row }">
                  <el-link
                      type="primary"
                      :underline="false"
                      @click="handleEdit(row)"
                  >编辑</el-link>
                  -
                  <el-link
                      type="primary"
                      :underline="false"
                      @click="handleDelete(row)"
                  >删除</el-link>
                  -
                  <el-link
                      type="primary"
                      :underline="false"
                      @click="handleDeviceManage(row)"
                  >设备管理</el-link>
              </template>
          </el-table-column>
      </el-table>
    </el-dialog>
    <template v-if="unitDetailVisible">
      <community-unit-detail
        :visible.sync="unitDetailVisible" 
        :building-id="buildingId"
        :unit-id="selectedUnitId"
        @close="unitDetailDialogClose"
        :title="unitDetailTitle"
      ></community-unit-detail>
    </template>
    <template v-if="deviceVisible">
        <community-device-list
            :visible.sync="deviceVisible" 
            :structure-id="selectedUnitId"
            structure-type="2"
            :title="deviceListTitle"
            @close="deviceListDialogClose"
        >
        </community-device-list>
    </template>
  </div>
</template>
<script>
import communityUnitApi from "@/api/base/communityUnit";
import CommunityUnitDetail from './communityUnit-detail.vue';
import CommunityDeviceList from './communityDevice-list.vue';

export default {
  components:{
      "community-unit-detail": CommunityUnitDetail,
      "community-device-list": CommunityDeviceList
  },
  props: {
      "visible": {
          type: Boolean,
          default: false
      },
      "buildingId": {
          type: String,
          default: ""
      }
  },
  data() {
    return {
      unitList: [],
      multipleSelection: [],
      loading: false,
      unitDetailVisible: false,
      selectedUnitId: "",
      unitDetailTitle: "",
      changed: false,
      deviceVisible: false,
      deviceListTitle: ""
    }
  },
  mounted() {
    this.loadUnitList();
  },
  methods: {
    loadUnitList() {
      var formData = new FormData();
      formData.append("buildingId", this.buildingId);

      this.loading = true;

      communityUnitApi.list(formData).then(resp=>{
        var jsonData = resp.data;

        if(jsonData.result){
          this.unitList = jsonData.data;
        }

        this.loading = false;
      });
    },
    handleAdd() {
      this.unitDetailTitle = "添加单元信息";
      this.selectedUnitId = "";
      this.unitDetailVisible = true;
    },
    handleEdit(row) {
      this.unitDetailTitle = "编辑单元信息";
      this.selectedUnitId = row.id;
      this.unitDetailVisible = true;
    },
    handleDelete(row) {
      var self = this;

      self.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        communityUnitApi.remove(row.id).then(function(response) {
          var jsonData = response.data;
          self.changed = true;

          if (jsonData.result) {
            self.loadUnitList();

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        communityUnitApi.batchRemove(idList).then((response)=>{
          var jsonData = response.data;

          if (jsonData.result) {
            self.changed = true;

            self.loadUnitList();

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    closeDialog() {
      this.$emit("close",this.changed);
    },
    unitDetailDialogClose(result) {
      if(result){
        this.loadUnitList();
        this.changed = true;
      }

      this.unitDetailVisible = false;
    },
    handleDeviceManage(row) {
        this.selectedUnitId = row.id;
        this.deviceListTitle = row.name + "关联设备管理";
        this.deviceVisible = true;
    },
    deviceListDialogClose(result){
        this.deviceVisible = false;
    }
  }
}
</script>

<style>

</style>