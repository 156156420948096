<template>
  <div>
      <el-table
            ref="roomTable"
            :data="tableData"
            :height="roomTableHeight"
            v-loading="loading"
            @selection-change="handleSelectionChange"
            stripe
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="name" label="楼栋户室" width="200"></el-table-column>
            <el-table-column prop="number" label="门牌号" width="100"></el-table-column>
            <el-table-column label="居住人员" width="400">
                <template slot-scope="{row}">
                    <div class="member-list">
                        <div class="member-item" v-for="(member,index) in row.memberList" :key="index">
                            <div class="member-tag" :class="{
                                owner: member.personRoleId=='6', 
                                family: member.personRoleId=='7', 
                                tenant: member.personRoleId=='8'}">
                            {{displayMemberTag(member)}}
                            </div>
                            <span v-html="member.personName"></span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
                <template slot-scope="{row}">
                    <el-link
                    type="primary"
                    :underline="false"
                    @click="handleEdit(row)"
                    >编辑</el-link>
                    -
                    <el-link
                    type="primary"
                    :underline="false"
                    @click="handleRemove(row)"
                    >删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            :current-page.sync="pageIndex"
            :total="totalElements"
            :page-sizes="pageSizeList"
            @current-change="changePage"
            @size-change="pageSizeChange"
            layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
  </div>
</template>
<script>
import communityRoomApi from '@/api/base/communityRoom'
export default {
    name: "roomTable",
    props: {
        communityId: {
            type: String,
            default: ""
        },
        buildingId: {
            type: String,
            default: ""
        },
        unitId: {
            type: String,
            default: ""
        },
        roomName: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            tableData: [],
            roomTableHeight: 350,
            pageIndex: 1,
            pageSize: 20,
            totalElements: 0,
            pageSizeList: [20, 30,50],
            loading: false
        }
    },
    mounted() {
        //this.roomTableHeight = window.innerHeight - 280;
        this.changePage(1);
    },
    methods: {
        changePage(pageIndex) {
            if(this.communityId==null || this.communityId.length==0){
                return false;
            }

            this.loading = true;

            this.pageIndex = pageIndex;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);

            formData.append("communityId", this.communityId);
            formData.append("buildingId", this.buildingId);
            formData.append("unitId", this.unitId);
            formData.append("roomName", this.roomName);

            communityRoomApi
            .pageList(formData)
            .then((response)=>{
                this.loading = false;

                var jsonData = response.data.data;

                this.tableData = jsonData.data;
                this.totalPages = jsonData.totalPages;
                this.totalElements = jsonData.recordsTotal;
            })
            .catch((error) => {
                this.loading = false;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.changePage(1);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        batchDeleteRoom() {
            if(this.multipleSelection.length==0) {
                this.$message({
                    type: "warning",
                    message: "未选择房间号!"
                });

                return;
            }

            this.$confirm("是否确认删除选中项？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                var roomIdList = [];

                this.multipleSelection.forEach(item=>{
                    roomIdList.push(item.id);
                });

                this.loading = true;

                communityRoomApi.batchDelete({
                    roomIdList
                })
                .then((response)=>{
                    this.loading = false;
                
                    var jsonData = response.data;

                    if (jsonData.result) {
                        this.$message({
                            type: "success",
                            message: "删除成功!"
                        });
                    }

                    this.changePage(1);

                    this.$emit("change",true);
                });
            });
        },
        handleEdit(row) {
            console.log("handleEdit");
            
            this.$emit("rowEdit",row);
        },
        handleRemove(row) {
            this.$confirm("是否确认删除选中项？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                var roomIdList = [row.id];

                this.loading = true;

                communityRoomApi.batchDelete({
                    roomIdList
                })
                .then((response)=>{
                    this.loading = false;
                
                    var jsonData = response.data;

                    if (jsonData.result) {
                        this.$message({
                            type: "success",
                            message: "删除成功!"
                        });
                    }

                    this.changePage(1);

                    this.$emit("change",true);
                });
            });
        },
        displayMemberTag(member) {
            var text = "";

            switch(member.personRoleId){
                case "6":
                    text = "业主";
                    break;
                case "7":
                    text = "家属";
                    break;
                case "8":
                    text = "租户";
                    break;    
            }

            return text;
        }
    }
}
</script>
<style scoped>
.member-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.member-item{
    margin:0px 5px 5px 0px;
}

.member-tag {
    display:inline-block;
    border-radius: 4px;
    min-width: 30px;
    padding: 0px 4px;
    height: 24px;
    margin-right: 5px;
    margin-left: 5px;
}

.owner {
    background-color: #409eff;
    color: white;
}

.family {
    background-color: #67c23a;
    color: white;
}

.tenant{
    background-color: #e6a23c;
    color: white;
}
</style>