<template>
<div style="padding-left: 5px;position:relative;">
    <el-breadcrumb separator=">">
        <el-breadcrumb-item>
            小区:
            <el-select size="mini" filterable remote 
            :remote-method="queryCommunityList"
            v-model="communityId" style="width:150px;">
                <el-option
                v-for="item in communityList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                ></el-option>
            </el-select>
        </el-breadcrumb-item>
        <el-breadcrumb-item><div style="line-height:24px;">房屋管理</div></el-breadcrumb-item>
    </el-breadcrumb>
    <div class="btn-import">
        <el-button size="mini" type="primary" @click="handleImportFromHikCloud">云眸平台导入</el-button>
    </div>
    <el-divider></el-divider>
    <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane label="户室信息" name="room">
            <!--房屋统计信息-->
            <div class="title-message">
                <div class="tm-item">
                    <!--已入住户数-->
                    <div class="tm-icon tm-icon1">
                        <div class="ct-icon blue-icon"></div>
                    </div>
                    <div class="ds">入住户数:{{occupyNum}}</div>
                </div>
                <div class="tm-item">
                    <!--未入住户数-->
                    <div class="tm-icon tm-icon1">
                        <div class="ct-icon gray-icon"></div>
                    </div>
                    <div class="ds">空置户数:{{emptyNum}}</div>
                </div>
            </div>

            <!--查询条件-->
            <el-form
            ref="queryForm"
            inline style="text-align:left;"
            >
                <el-form-item label="楼栋">
                <el-select size="mini" v-model="buildingId" style="width:150px;">
                    <el-option label="所有" value=""></el-option>
                    <el-option
                    v-for="item in buildingList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="单元">
                <el-select size="mini" v-model="unitId" style="width:150px;">
                    <el-option label="所有" value=""></el-option>
                    <el-option
                    v-for="item in unitList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    ></el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="门牌号">
                    <el-input v-model="roomName" size="mini"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" @click="queryRoomList()">查询</el-button>
                    <el-button type="reset" size="small" @click="resetForm('queryForm')">重置</el-button>
                </el-form-item>
            </el-form>
            <el-row>
                <el-col :span="12" style="text-align:left;">
                    <template v-if="mode=='图示'">
                        <el-button type="primary" size="mini" @click="toggleEditMode">{{editMode ? "退出编辑模式" : "进入编辑模式"}}</el-button>
                        <template v-if="editMode">
                            <!-- <el-button icon="el-icon-plus" size="mini">新增户室</el-button> -->
                            <el-button icon="el-icon-delete" size="mini" 
                            @click="batchDeleteRoom" :loading="delLoading">批量删除</el-button>
                        </template>
                    </template>
                    <template v-else>
                        <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAddRoom">添加</el-button>
                        <el-button icon="el-icon-delete" size="mini" @click="handleDeleteRoom">删除</el-button>
                    </template>
                </el-col>
                <el-col :span="12" style="text-align:right;">
                    <el-radio-group v-model="mode" size="mini">
                        <el-radio-button label="图示"></el-radio-button>
                        <el-radio-button label="列表"></el-radio-button>
                    </el-radio-group>
                </el-col>
            </el-row>
            <div>
                <!--图示-->
                <template v-if="mode=='图示'">
                    <room-diagram ref="roomDiagram" :edit-mode="editMode" 
                    :building-list="filterBuildingList"
                    v-model="checkedItems"
                    @roomClick="handleEditRoom"
                    ></room-diagram>
                </template>
                <template v-else>
                    <room-table ref="roomTable" 
                    :community-id="communityId"
                    :building-id="buildingId" 
                    :unit-id="unitId" 
                    :room-name="roomName"
                    @change="roomTableChanged"
                    @rowEdit="handleEditRoom"
                    ></room-table>
                </template>
            </div>            
            <template v-if="roomVisible">
                <community-room-detail 
                    :visible.sync="roomVisible" 
                    :community-id="communityId"
                    :room-id="selectedRoomId"
                    :title="roomDetailTitle"
                    @close="roomDetailClose"
                ></community-room-detail>
            </template>
        </el-tab-pane>
        <el-tab-pane label="楼栋管理" name="building">
            <div style="text-align:left;">
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="addBuilding" :disabled="this.communityId.length==0">添加</el-button>
                <el-button icon="el-icon-delete" size="mini" :disabled="this.selectedBuildings.length==0" @click="batchDeleteBuilding">删除</el-button>
            </div>
            <el-table
                :data="buildingList"
                v-loading="loading"
                stripe
                @selection-change="handleSelectionBuildingChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="name" label="楼栋名称" width="200"></el-table-column>
                <el-table-column prop="buildingUnitSize" label="单元数量" width="100"></el-table-column>
                <el-table-column prop="floorUpCount" label="地上楼层数" width="100"></el-table-column>
                <el-table-column prop="floorDownCount" label="地下楼层数" width="100"></el-table-column>
                <el-table-column prop="floorFamilyCount" label="每层户数" width="100"></el-table-column>
                <el-table-column label="操作" fixed="right">
                    <template slot-scope="{ row }">
                        <el-link
                            type="primary"
                            :underline="false"
                            @click="handleBuildingEdit(row)"
                        >编辑</el-link>
                        -
                        <el-link
                            type="primary"
                            :underline="false"
                            @click="handleBuildingDelete(row)"
                        >删除</el-link>
                        -
                        <el-link
                            type="primary"
                            :underline="false"
                            @click="handleUnitManage(row)"
                        >单元管理</el-link>
                        -
                        <el-link
                            type="primary"
                            :underline="false"
                            @click="handleDeviceManage(row)"
                        >设备管理</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <template v-if="buildingVisible">
                <community-building-detail 
                    :visible.sync="buildingVisible" 
                    :community-id="communityId"
                    :building-id="selectedBuildingId"
                    :title="buildingTitle"
                    @close="buildingDetailClose"
                ></community-building-detail>
            </template>
            <template v-if="unitVisible">
                <community-unit-list 
                :visible.sync="unitVisible" 
                :building-id="selectedBuildingId"
                @close="unitListDialogClose">
                </community-unit-list>
            </template>
            <template v-if="deviceVisible">
                <community-device-list
                    :visible.sync="deviceVisible" 
                    :structure-id="selectedBuildingId"
                    structure-type="1"
                    :title="deviceListTitle"
                    @close="deviceListDialogClose"
                >
                </community-device-list>
            </template>
            
            <template v-if="roomImportVisible">
                <community-room-import
                    :visible.sync="roomImportVisible" 
                    @close="roomImportDialogClose"
                >
                </community-room-import>
            </template>
        </el-tab-pane>
    </el-tabs>
</div>
</template>

<script>
import RoomDiagram from '@/components/RoomDiagram'
import RoomTable from '@/components/RoomTable'
import CommunityBuildingDetail from './communityBuilding-detail.vue';
import CommunityRoomDetail from './communityRoom-detail.vue';
import CommunityUnitList from './communityUnit-list.vue';
import CommunityDeviceList from './communityDevice-list.vue';
import CommunityRoomImport from './communityRoom-import.vue';

import companyInfoApi from "@/api/base/companyInfo";
import communityBuildingApi from '@/api/base/communityBuilding';
import communityRoomApi from '@/api/base/communityRoom';

export default {
    name: "BaseCommunityRoomList",
    components:{
        "room-diagram": RoomDiagram,
        "room-table": RoomTable,
        "community-building-detail": CommunityBuildingDetail,
        "community-unit-list": CommunityUnitList,
        "community-device-list": CommunityDeviceList,
        "community-room-detail": CommunityRoomDetail,
        "community-room-import": CommunityRoomImport
    },
    data() {
        return {
            communityList: [],
            buildingList: [],
            unitList: [],
            communityId: "",
            buildingId: "",
            unitId: "",
            roomName: "",
            activeName: "room",
            mode: "图示",
            editMode: false,
            loading: false,
            selectedBuildings: [],
            buildingVisible: false,
            buildingTitle: "",
            roomTableHeight: 300,
            remoteRoomList: [],
            selectedBuildingId: "",
            checkedItems: [],
            delLoading: false,
            unitVisible: false,
            deviceVisible: false,
            deviceListTitle: "",
            roomVisible: false,
            selectedRoomId: "",
            roomDetailTitle: "",
            roomImportVisible: false
        }
    },
    mounted() {
        this.queryCommunityList('');
    },
    watch: {
        communityId(newVal,oldVal) {
            if(newVal!=null){
                this.queryBuildingList(newVal);
            }
        },
        buildingId(newVal,oldVal) {
            if(newVal.length>0){
                this.unitList = this.buildingList.filter(item=>item.id==newVal)[0].unitList;
            }
            else{
                this.unitList = [];
            }
            
            if(this.unitList.filter(unit=>unit.id==this.unitId).length==0){
                this.unitId = "";
            }
        }
    },
    computed: {
        filterBuildingList() {
            return this.buildingList.reduce((acc,building)=>{
                //过滤楼栋
                var item = this.filterBuilding(building);

                if(item!=null){
                    acc.push(item);
                }
                
                return acc;
            },[]);
        },
        filterRoomList() {
            var roomList = [];

            this.filterBuildingList.forEach(building=>{
                building.unitList.forEach(unit=>{
                    unit.floorList.forEach(floor=>{
                        floor.roomList.forEach(room=>{
                            roomList.push(room);
                        });
                    });
                });
            });

            return roomList;
        },
        occupyNum() {
            var count = this.filterRoomList.filter(item=>item.personCount!=null && item.personCount>0).length;
            return count;
        },
        emptyNum() {
            var count = this.filterRoomList.filter(item=>item.personCount==null || item.personCount==0).length;
            return count;
        }
    },
    methods: {
        queryBuildingList(communityId) {
            //查询楼栋+单元+房间
            var formData = new FormData();
            formData.append("communityId", communityId);
            formData.append("includeRoom", true);

            communityBuildingApi.queryBuildingList(formData)
            .then(resp=>{
                var jsonData = resp.data;

                if(jsonData.result){
                    this.buildingList = jsonData.data;

                    if(this.buildingList.filter(building=>building.id==this.buildingId).length==0){
                        this.buildingId = "";
                    }
                }
            });
        },
        queryRoomList() {
            if(this.$refs.roomTable!=null){
                this.$refs.roomTable.changePage(1);
            }
        },
        filterBuilding(building) {
            if(this.buildingId.length==0 || this.buildingId == building.id){
                //过滤单元
                var unitList = this.filterUnit(building);

                return {
                    id: building.id,
                    name: building.name,
                    unitList: unitList
                }
            }
            else{
                return null;
            }
        },
        filterUnit(building) {
            var unitList = building.unitList.reduce((uList,unit)=>{
                if(this.unitId.length==0 || this.unitId == unit.id){
                    //过滤楼层
                    var floorList = this.filterFloor(unit);
                    //var floorList = unit.floorList;

                    uList.push({
                        id: unit.id,
                        name: unit.name,
                        floorList: floorList
                    });
                }

                return uList;
            },[]);

            return unitList;
        },
        filterFloor(unit){
            var floorList = unit.floorList.reduce((fList,floor)=>{
                //过滤房间
                var roomList = this.filterRoom(floor);

                if(roomList!=null && roomList.length>0){
                    fList.push({
                        roomList: roomList
                    });
                }

                return fList;
            },[]);

            return floorList;
        },
        filterRoom(floor) {
            if(this.roomName.length>0){
                return floor.roomList.filter(room=>{
                    return room.number.indexOf(this.roomName)!=-1;
                });
            }
            else{
                return floor.roomList
            }
        },
        tabClick() {

        },
        toggleEditMode() {
            this.editMode = !this.editMode;
        },
        batchDeleteRoom() {
            this.$confirm("是否确认删除选中项？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                //this.$refs.roomDiagram.deleteSelectedRoom();
                var unitIdList = [];
                var roomIdList = [];

                this.checkedItems.forEach(value=>{
                    var arr = value.split(',');

                    var i = parseInt(arr[0]);
                    var j = parseInt(arr[1]);

                    if(arr.length==2){
                        //选择单元
                        unitIdList.push(this.filterBuildingList[i].unitList[j].id);
                    }
                    else{
                        //选择房间
                        var k = parseInt(arr[2]);
                        var roomId = arr[3];

                        roomIdList.push(roomId);
                    }
                });

                this.delLoading = true;

                communityRoomApi.batchDelete({
                    unitIdList,roomIdList
                })
                .then((response)=>{
                    this.delLoading = false;
                
                    var jsonData = response.data;

                    if (jsonData.result) {
                        this.$message({
                            type: "success",
                            message: "删除成功!"
                        });

                        this.checkedItems = []; //重置选择项

                        this.queryBuildingList(this.communityId);
                    }
                });
            });
        },
        handleSelectionBuildingChange(val) {
            this.selectedBuildings = val;
        },
        handleBuildingEdit(row) { 
            this.buildingTitle = "编辑楼栋信息";
            this.selectedBuildingId = row.id;
            this.buildingVisible = true;
        },
        addBuilding() {
            this.buildingTitle = "添加楼栋信息";
            this.selectedBuildingId = "";
            this.buildingVisible = true;
        },
        queryCommunityList(keywords) {
            var formData = new FormData();

            formData.append("unitType","3");
            formData.append("sceneId", "2");
            formData.append("name", keywords);
            formData.append("pageIndex", 1);
            formData.append("pageSize", 20);

            companyInfoApi.pageList(formData).then(resp=>{
                var jsonData = resp.data;

                if(jsonData.result){
                    this.communityList = jsonData.data.data;
                }
            });
        },
        resetForm(formName) {
            //this.$refs[formName].resetFields();

            if(formName=='queryForm') {
                this.buildingId = "";
                this.unitId = "";
                this.roomName = "";
            }
        },
        buildingDetailClose(result){
            if(result) {
                this.queryBuildingList(this.communityId);
                this.buildingVisible = false;
            }
        },
        batchDeleteBuilding() {
            var idList = this.selectedBuildings.map((record) => {
                return record.id;
            });

            this.$confirm("是否确认删除选中项？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                communityBuildingApi.batchDelete(idList).then((response)=>{
                    var jsonData = response.data;

                    if (jsonData.result) {
                        this.$message({
                            type: "success",
                            message: "删除成功!"
                        });

                        this.queryBuildingList(this.communityId);
                    }
                });
            });
        },
        handleBuildingDelete(row) {
            var idList = [];
            idList.push(row.id);

            this.$confirm("是否确认删除选中项？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                communityBuildingApi.batchDelete(idList).then((response)=>{
                    var jsonData = response.data;

                    if (jsonData.result) {
                        this.$message({
                            type: "success",
                            message: "删除成功!"
                        });

                        this.queryBuildingList(this.communityId);
                    }
                });
            });
        },
        roomTableChanged() {
            this.queryBuildingList(this.communityId);
        },
        handleDeleteRoom() {
            this.$refs.roomTable.batchDeleteRoom();
        },
        handleAddRoom() {
            this.selectedRoomId = "";
            this.roomDetailTitle="添加房屋信息";
            this.roomVisible = true;
        },
        handleEditRoom(row) {
            this.selectedRoomId = row.id;
            this.roomDetailTitle="修改房屋信息";
            this.roomVisible = true;
        },
        unitListDialogClose(result) {
            if(result) {
                this.queryBuildingList(this.communityId);
            }
            
            this.unitVisible = false;
        },
        handleUnitManage(row) {
            this.selectedBuildingId = row.id;
            this.unitVisible = true;
        },
        handleDeviceManage(row) {
            this.selectedBuildingId = row.id;
            this.deviceListTitle = row.name + "关联设备管理";
            this.deviceVisible = true;
        },
        deviceListDialogClose(result){
            this.deviceVisible = false;
        },
        roomDetailClose(result) {
            this.roomVisible = false;

            if(result){
                console.log("roomDetailClose");

                if(this.$refs.roomTable!=null){
                    this.$refs.roomTable.changePage(1);
                }
                
                this.queryBuildingList(this.communityId);
            }
        },
        handleImportFromHikCloud() {
            //从云眸平台导入小区
            this.roomImportVisible = true;
        },
        roomImportDialogClose(result) {
            this.roomImportVisible = false;

            if(result) {
                this.queryCommunityList('');
            }
        }
    }
}
</script>

<style scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.title-message{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    background: #fff;
}

.tm-item{
    width: 250px;
    height: 64px;
    background: #fff;
    border: 1px solid rgba(0,0,0,.08);
    border-radius: 4px;
    margin-right: 30px;
}

.title-message .tm-item .tm-icon1 {
    background: #dbeaff;
}

.tm-icon {
    width: 32px;
    height: 32px;
    margin-top: 16px;
    margin-left: 16px;
    border-radius: 2px;
    position: relative;
    float: left;
}

.ct-icon {
    width: 16px;
    height: 16px;
    margin: 0 auto;
    position: absolute;
    top: 25%;
    left: 25%;
    border-radius: 2px;
    z-index: 1;
}

.blue-icon
{
    background: #3c91e1;
}

.gray-icon
{
    background:#c9d1dc;
}

.ds{
    float: left;
    font-size: 14px;
    color: rgba(0,0,0,.7);
    letter-spacing: 0;
    line-height: 64px;
    margin: 0 16px;
}

.btn-import{
    position: absolute;
    right: 20px;
    top: 5px;
}
</style>