import request from '@/utils/request'
import constant from '@/constant'

function list(formData){
  return request.post(constant.serverUrl + "/base/communityDevice/list", formData);
}

function relateDevice(formData,){
  return request.post(constant.serverUrl + "/base/communityDevice/relateDevice",formData);
}

export default {
  list,relateDevice
}