<template>
  <div>
    <el-dialog
        :visible="visible"
        title="选择要导入的小区"
        :modal-append-to-body="true"
        append-to-body
        :close-on-click-modal="false"
        style="text-align: left"
        width="80%"
        @close="closeDialog"
    >
      <el-table
          :data="tableData"
          v-loading="loading"
          :element-loading-text="loadingText"
          stripe
      >
          <el-table-column prop="communityName" label="小区名称" width="200"></el-table-column>
          <el-table-column prop="addressDetail" label="小区地址" width="300"></el-table-column>
          <el-table-column label="操作" fixed="right">
              <template slot-scope="{ row }">
                  <el-link
                      type="primary"
                      :underline="false"
                      @click="handleImport(row)"
                  >导入</el-link>
              </template>
          </el-table-column>
      </el-table>
      <el-pagination
            :current-page.sync="pageIndex"
            :total="totalElements"
            :page-sizes="pageSizeList"
            @current-change="changePage"
            @size-change="pageSizeChange"
            layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
    </el-dialog>
  </div>
</template>
<script>
import communityInfoApi from "@/api/base/communityInfo";

export default {
    props: {
      "visible": {
          type: Boolean,
          default: false
      }
    },
    data() {
        return {
            tableData: [],
            pageIndex: 1,
            pageSize: 20,
            totalElements: 0,
            pageSizeList: [20, 30,50],
            loading: false,
            loadingText: ""
        }
    },
    methods: {
        changePage(pageIndex) {
            this.loadingText="加载中...";
            this.loading = true;

            this.pageIndex = pageIndex;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);

            communityInfoApi
            .hikCloudCommunityList(formData)
            .then((response)=>{
                this.loading = false;

                var jsonData = response.data.data;

                this.tableData = jsonData.rows;
                this.totalPages = jsonData.totalPage;
                this.totalElements = jsonData.total;
            })
            .catch((error) => {
                this.loading = false;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.changePage(1);
        },
        closeDialog() {
            this.$emit("close", false);
        },
        handleImport(row) {
            this.loadingText="导入中...";
            this.loading = true;

            communityInfoApi.importCommunityFromHikCloud(row)
            .then(resp=>{
                this.loading = false;

                var jsonData = resp.data;

                if(jsonData.result){
                    this.$message({
                        type: "success",
                        message: "导入完毕!"
                    });

                    this.$emit("close", true);
                }
                else{
                    this.$message({
                        type: "error",
                        message: jsonData.message + ""
                    });
                }
            });
        }
    },
    mounted() {
        this.changePage(1);
    }
}
</script>

<style>

</style>