<template>
    <el-dialog
        :visible="visible"
        :title="title"
        :modal-append-to-body="true"
        append-to-body
        :close-on-click-modal="false"
        style="text-align: left"
        width="60%"
        @close="closeDialog"
    >
        <div class="user-panel" v-loading="loading">
            <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'120px'" :inline="true">
                <el-form-item label="楼栋名称" prop="name">
                    <el-input v-model="formModel.name" placeholder="请输入楼栋名称" style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="楼栋编号" prop="number">
                    <el-input v-model="formModel.number" placeholder="请输入楼栋编号" style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="地上楼层数" prop="floorUpCount">
                    <el-input
                        v-model="formModel.floorUpCount"
                        placeholder="请输入地上楼层数"
                        style="width:200px"
                    ></el-input>
                </el-form-item>
                <el-form-item label="地下楼层数" prop="floorDownCount">
                    <el-input
                        v-model="formModel.floorDownCount"
                        placeholder="请输入地下楼层数"
                        style="width:200px"
                    ></el-input>
                </el-form-item>
                <el-form-item label="楼栋单元数量" prop="buildingUnitSize">
                    <el-input
                        v-model="formModel.buildingUnitSize"
                        placeholder="请输入楼栋单元数量"
                        style="width:200px"
                    ></el-input>
                </el-form-item>
                <el-form-item label="每层户数" prop="floorFamilyCount">
                    <el-input
                        v-model="formModel.floorFamilyCount"
                        placeholder="请输入每层户数"
                        style="width:200px"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleSubmit" :loading="loading">{{buildingId==null || buildingId.length==0 ? "添加" : "更新"}}</el-button>
            <el-button @click="closeDialog">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import communityBuildingApi from '@/api/base/communityBuilding'

export default {
    props: {
        "visible": {
            type: Boolean,
            default: false
        },
        "title": {
            type: String,
            default: "楼栋信息"
        },
        "communityId": {
            type: String,
            default: ""
        },
        "buildingId": {
            type: String,
            default: ""
        }
    },
    data() {
        var validateNumber = (rule, value, callback)=>{
            setTimeout(() => {
                var number = parseInt(value);
                var min = parseInt(rule.min);

                if (!Number.isInteger(number)) {
                    callback(new Error(rule.message));
                } else {
                    if (number< min) {
                        callback(new Error(rule.message));
                    } else {
                        callback();
                    }
                }
            }, 100);
        }

        return {
            ruleValidate: {
                name: [
                    { required: true, message: '楼栋名称不能为空', trigger: 'blur' }
                ],
                number: [
                    { required: true, message: '楼栋编号不能为空', trigger: 'blur' },
                    { validator: validateNumber,min:0, message: '楼栋编号不能为负数',trigger: 'blur'}
                ],
                floorUpCount: [
                    { required: true, message: '地上楼层数不能为空', trigger: 'blur' },
                    { validator: validateNumber,min:0, message: '地上楼层数不能为负数',trigger: 'blur'}
                ],
                floorDownCount: [
                    { required: true, message: '地下楼层数不能为空', trigger: 'blur' },
                    { validator: validateNumber,min:0, message: '地下楼层数不能为负数',trigger: 'blur'}
                ],                    
                buildingUnitSize: [
                    { required: true, message: '楼栋单元数量不能为空', trigger: 'blur' },
                    { validator: validateNumber,min:1, message: '楼栋单元数量要为正整数',trigger: 'blur'}
                ],                    
                floorFamilyCount: [
                    { required: true, message: '每层户数不能为空', trigger: 'blur' },
                    { validator: validateNumber,min:1, message: '每层户数数量要为正整数',trigger: 'blur'}
                ]       
            },
            showDialog: true,
            loading: false,
            submitting: false,
            formModel: {}
        };
    },
    watch: {
        // visible(newVal,oldVal) {
        //     if(newVal){
        //         this.formModel = {};
        //         this.loadRemoteBuilding();
        //     }
        // }
    },
    mounted() {
        this.loadRemoteBuilding();
    },
    methods: {   
        loadRemoteBuilding() {
            var self = this;

            (function () {
                self.loading = true;
                
                if (self.buildingId==null || self.buildingId.length == 0) {
                    return communityBuildingApi.create();
                } else {
                    return communityBuildingApi.edit(self.buildingId);
                }
            })().then((response) => {
                var jsonData = response.data;
                self.loading = false;

                if (jsonData.result) {
                    self.formModel = jsonData.data;
                } else {
                    self.$message.error(jsonData.message + "");
                }
            })
            .catch((error) => {
                self.$message.error(error + "");
            });
        },
        handleSubmit() {
            var self = this;
            self.formModel.communityId = self.communityId;

            this.$refs["form"].validate((valid) => {
                if (valid) {
                    (function () {
                        var id = self.formModel.id;
                        self.loading = true;

                        if (id == null || id.length == 0) {
                            return communityBuildingApi.add(self.formModel);
                        } else {
                            return communityBuildingApi.update(self.formModel);
                        }
                    })().then(function (response) {
                        self.loading = false;

                        var jsonData = response.data;

                        if (jsonData.result) {
                            self.$message({
                                message: "保存成功!",
                                type: "success",
                            });

                            self.$emit("close", true);
                        } else {
                            self.$message({
                                message: jsonData.message + "",
                                type: "warning",
                            });

                            self.$emit("close", false);
                        }
                    });
                }
            });
        },
        closeDialog() {
            this.$emit("update:visible",false);
        }
    }
}
</script>

<style>
</style>