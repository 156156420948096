<template>
  <div>
    <el-dialog
    :visible="visible"
    :title="title"
    :modal-append-to-body="false"
    append-to-body
    style="text-align:left;"
    @close="closeDialog"
  >
      <el-table
        :data="tableData"
        style="min-height: 400px"
        v-loading="loading"
        stripe
      >
        <el-table-column
          prop="deviceNo"
          label="设备编号"
          width="220"
        ></el-table-column>
        <el-table-column
          prop="aliasName"
          label="设备名称"
          width="180"
        ></el-table-column>
        <el-table-column prop="isOnline" label="运行状态" width="80">
          <template slot-scope="{ row }">
            <div
              v-if="row.isOnline"
              style="
                border-radius: 30px;
                background-color: #67c23a;
                width: 20px;
                height: 20px;
              "
            ></div>
            <div
              v-if="!row.isOnline"
              style="
                border-radius: 30px;
                background-color: #f56c6c;
                width: 20px;
                height: 20px;
              "
            ></div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.enabled"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="enableChange(row)"
              >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import communityDeviceApi from "@/api/base/communityDevice";

export default {
  props: {
    "visible": {
      type: Boolean,
      default: false
    },
    "structureId": {
      type: String,
      default: ""
    },
    "structureType": {
      type: String,
      default: ""
    },
    "title": {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      tableData: [],
      loading: false
    }
  },
  mounted() {
    this.queryDeviceList();
  },
  methods: {
    closeDialog() {
      this.$emit("close",false);
    },
    queryDeviceList() {
        var formData = new FormData();

        formData.append("structureId",this.structureId);
        formData.append("structureType", this.structureType);

        communityDeviceApi.list(formData).then(resp=>{
            var jsonData = resp.data;

            if(jsonData.result){
                this.tableData = jsonData.data;
            }
        });
    },
    enableChange(row) {
        var formData = new FormData();

        formData.append("structureId",this.structureId);
        formData.append("structureType", this.structureType);
        formData.append("deviceId", row.deviceId);
        formData.append("enabled", row.enabled);

        communityDeviceApi.relateDevice(formData).then(resp=>{
            var jsonData = resp.data;

            if(!jsonData.result){
                this.$message.error(jsonData.message + "");
            }
        });
    }
  }
}
</script>
<style>

</style>